
<template>
  <div>
    <div class="hero-section wf-section"
         style="height: fit-content; min-height: 80vh !important; background-image: linear-gradient(133deg, #0f446d 26%, #32717d)"
    >
      <div class="div-block-3"
           style="height: fit-content;  min-height: 80vh !important; justify-content: center"
      >
        <div
          id="w-node-_68388a41-1492-a0f9-e803-23fbb9653b53-a17b101c"
          class="div-block-2"
          style="height: fit-content; min-height: 80vh !important; width: 70%; margin: 20px 0; min-width: 270px; max-width: unset;"

        >
        <Formulario />

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "../helpers/api/planes";
import Formulario from "@/components/Contacto/Formulario";
export default {
  name: "Contacto",
  components: {Formulario},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    rules: {
      required: value => !!value || 'Requerido',
      phone: value => value && value.length === 11 || 'Debe tener 11 dígtos. Ej (56912345678)',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email no es válido'
      },
    },

    items: [
"Despido injustificado.",
"Despido indirecto",
"Sindicatos (Constitución y asesorías)",
"Negociación colectiva",
"Accidente laboral y enfermedades profesionales",
"Acoso laboral",
"Autodespido",
"Prenatal y post natal",
"Muerte del trabajador",
"Denuncias Inspección del Trabajo",
],
    userForm: {
      name: '',
      rut: '',
      email: '',
      telefono: '',
      direccion: '',
      tipoDenuncia: "Despido injustificado.",
      descripcion: '',
    },
    loading: false,
    requestedStatus: false,
    playing: false,
  }),
  mounted() {
    // if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
    //   this.getOrderStatus(this.$route.query.token);
    // }
    // setTimeout(() => {
    //   this.togglePlay();
    // }, 1000);
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        alert('Formulario enviado')
      }
    },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          const payUrl =
            planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sos-abogados.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

.v-application a {
  color: #000 !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>