
<template>
  <div
    style="padding: 20px; background-color: #59c0d4; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; margin-bottom: 20px"
  >
    <div class="wf-section">
      <div class="div-block-10">
        <div class="div-block-11-CF">

          <div class="hero-section-CF wf-section"
          >
            <div class="div-block-3"
            >
              <div
                  id="w-node-_68388a41-1492-a0f9-e803-23fbb9653b53-a17b101c"
                  class="div-block-2-CF"

              ><br><br>
                <h1 class="heading-5 color-CF">Como Funciona</h1>
                <div class="div-block-4" style="margin-bottom: 20px"></div>

                <h4 class="white--text">Es muy fácil</h4>

                <p class="paragraph justificar color-CF">
                  Estamos a tan solo un click, en donde al presionar el botón contactar estaremos contigo a través de una video
                  llamada en donde podrás tener una reunión en forma inmediata con uno de nuestros profesionales quienes te
                  orientaran sin costo en tu consulta.
                </p>
                <h4 class="white--text">
                  Somos abogados laborales cercanos a ti y a tus necesidades.
                </h4>
                <p class="paragraph justificar color-CF">
                  Nuestros Servicios y especialidades son: Demandas laborales por despido injustificado, despido indirecto, cobro
                  de prestaciones, tutelas por acoso laboral o sexual, procedimiento por vulneración de derecho entre otras.
                </p>
                <p class="paragraph justificar color-CF">
                  En materia administrativa, contamos con un equipo de abogados expertos en Estatuto Administrativo, Central y Municipal, avocados
                  a diversas materias tales como ascensos, defensa en sumarios, reclamos en Contraloría General, encasillamientos, reconocimiento 
                  de relación laboral a prestadores honorarios, traspaso forzado a contrata de los mismos, recursos de protección entre otras materias..
                </p>
                <p class="paragraph justificar color-CF">
                  Defensa en sumarios, reclamos en Contraloría General, encasillamientos, reconocimiento, relación laboral a prestadores honorarios,
                  traspaso forzado a contrata de los mismos, recursos de protección entre otras materias.
                </p>

              </div>
            </div>
          </div>

        </div>
        <div class="div-block-13-CF">
          <img
              src="../../assets/images/Abogado-conectados.png"
              loading="lazy"
              srcset="
              ../../assets/images/Abogado-conectados.png 500w,
              ../../assets/images/Abogado-conectados.png 550w
            "
              sizes="(max-width: 479px) 280px, (max-width: 767px) 300px, (max-width: 991px) 380px, 100vw"
              alt=""
              class="image-6-CF"
          />
        </div>
      </div>






    </div>
    <div class="div-block-10">
      <div class="div-block-11-CF">
        <h1 class="heading-5">
          Destacamos algunas Áreas y Servicios en Defensa Laboral
        </h1>

        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Despido injustificado</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Despido indirecto</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Sindicatos (Constitución y asesorías)</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Negociación colectiva</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Accidente laboral y enfermedades profesionales</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Acoso laboral</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Autodespido</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Prenatal y post natal</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Muerte del trabajador</h4>
        <h4 class="white--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Denuncias Inspección del Trabajo</h4>
        <br>
        <p class="paragraph justificar color-CF">
          Si necesitas un abogado laboral comprometido y capaz, que se adapte a tus necesidades, somos los indicados y nuestros especialistas laborales están atentos a tu llamado.
        </p>
        <p class="paragraph justificar color-CF">
          Tus derechos laborales son irrenunciables, nosotros estamos aquí para defenderlos y apoyarte.
        </p>
        <p class="paragraph justificar color-CF">
          Cuenta con nosotros y no esperes a que tu problema laboral se convierta en un problema personal, tus derechos son nuestra ley.
        </p>
        <p class="paragraph justificar color-CF">
          Todas nuestras gestiones son sin costo para ti, solo pagas al ganar el juicio estableciendo un porcentaje de lo que obtengas, y es por eso que somos tus aliados y estamos aquí para ti.
        </p>
        <p class="paragraph justificar color-CF">
          Cuéntanos tu historia y con el fin de facilitar nuestros canales de contacto hemos desarrollado además este formulario de contacto.
        </p><br>
<!--        <a href="/contacto" class="button _2 w-button">Contacto</a>-->
        <br><br><br>
      </div></div>
  </div>
</template>

<script>
import {getOrderStatusByToken, startPaymentPlan,} from "../helpers/api/planes";

export default {
  name: "ComoFunciona",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
  }),
  mounted() {
    // if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
    //   this.getOrderStatus(this.$route.query.token);
    // }
    // setTimeout(() => {
    //   this.togglePlay();
    // }, 1000);
  },
  methods: {
    // togglePlay() {
    //   this.playing = !this.playing;
    //   if (this.playing) {
    //     this.$refs.videoRef.play();
    //   } else {
    //     this.$refs.videoRef.pause();
    //   }
    // },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
            planPayment.flowData &&
            planPayment.flowData.url &&
            planPayment.flowData.token
        ) {
          window.location.href = planPayment.flowData.url + "?token=" + planPayment.flowData.token;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.media
              : "",
          fecha: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.date
              : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sos-abogados.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

.v-application a {
  color: #000 !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>