import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
import ComoFunciona from "@/components/comoFunciona";
import TiposDenuncia from "@/components/tiposDenuncia";
import Contacto from "@/components/Contacto";
// import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
// import Checkout from '@/components/checkout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/como_funciona',
    name: 'ComoFunciona',
    component: ComoFunciona
  },
  {
    path: '/tipos_de_denuncias',
    name: 'TiposDenuncias',
    component: TiposDenuncia
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  }
  // {
  //   path: '/Checkout',
  //   name: 'Checkout',
  //   component: Checkout
  // }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
