
<template>
  <div>
    <div class="hero-section wf-section">
      <div class="div-block-3">
        <div
          id="w-node-_68388a41-1492-a0f9-e803-23fbb9653b53-a17b101c"
          class="div-block-2"
        >
          <h1 class="heading">Bienvenido a "SOSCLICKAbogados"</h1>
          <div class="div-block-4"></div>
          <p class="paragraph justificar">
            Somos "SOSCLICKAbogados", profesionales expertos en derecho laboral y procedimientos administrativos 
            de funcionarios públicos, reconocimiento de relación laboral, ascensos, paso forzoso a contrata 
            y otros, puedes contactarnos a través de una videollamada y uno de nuestros profesionales te 
            orientará sin costo, entregándote la mejor solución de forma inmediata.
          </p>
          <a href="https://www.click2call.cl/?ShareLink=5" class="button w-button">Contactar un Abogado</a>
        </div>
        <div
          id="w-node-_2414bf48-490c-dd52-ff46-7e178532166b-a17b101c"
          class="div-block"
        >
          <img
            src="../../assets/images/Hero-imagen.png"
            loading="lazy"
            width="447"
            id="w-node-e05bf034-8ba8-d2f0-e5ff-818f2bcf9a74-a17b101c"
            alt=""
            class="image-2"
          />
        </div>
      </div>
    </div>
    <div class="section wf-section">
      <div class="card">
        <div class="div-block-6">
          <div class="div-block-7">
            <h1 class="heading-2">Como Funciona</h1>
            <p class="paragraph-3">Es muy fácil</p>
          </div>
        </div>
        <div class="div-block-15">
          <p class="paragraph-2 justificar">
            Solo debes presionar en el botón de contactar y se iniciará una
            videollamada con un abogado que te asistirá en la consulta que
            tengas.
          </p>
          <a href="/como_funciona" class="button _2 w-button">Ver más</a>
        </div>
      </div>
      <div class="div-block-16">
        <img
          src="../../assets/images/banner-2.png"
          loading="lazy"
          width="768"
          srcset="
            ../../assets/images/banner-2.png  500w,
            ../../assets/images/banner-2.png  800w,
            ../../assets/images/banner-2.png 1080w,
            ../../assets/images/banner-2.png 1088w
          "
          sizes="(max-width: 991px) 100vw, 81vw"
          alt=""
          class="image-4"
        />
      </div>
    </div>
    <div class="section-2 wf-section">
      <div class="div-block-8">
        <div class="card-shadow">
          <div class="div-block-6">
            <img
              src="../../assets/images/Icon-ionic-md-checkmark-circle.svg"
              loading="lazy"
              alt=""
              class="image-5"
            />
            <div class="div-block-7">
              <h1 class="heading-2">Abogados<br />Profesionales</h1>
              <div class="div-block-4"></div>
            </div>
          </div>
        </div>
        <div class="card-shadow">
          <div class="div-block-6">
            <img
              src="../../assets/images/Icon-ionic-md-checkmark-circle.svg"
              loading="lazy"
              alt=""
              class="image-5"
            />
            <div class="div-block-7 _2">
              <h1 class="heading-2">Consultas al instante</h1>
              <div class="div-block-4"></div>
            </div>
          </div>
        </div>
        <div class="card-shadow">
          <div class="div-block-6">
            <img
              src="../../assets/images/Icon-ionic-md-checkmark-circle.svg"
              loading="lazy"
              alt=""
              class="image-5"
            />
            <div class="div-block-7">
              <h1 class="heading-2 _3">Desde la comodidad de tu hogar</h1>
              <div class="div-block-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-3 wf-section"></div>
    <div class="section-4 wf-section">
      <div class="div-block-10 _2">
        <div class="div-block-11">
          <h1 class="heading-3">Tipos de Denuncias</h1>
          <p class="paragraph-4 justificar">
            Nuestras especialidades son: demandas laborales por despido injustificado, despido indirecto, 
            cobro de prestaciones, tutelas por acoso laboral o sexual, procedimiento por vulneración de derechos, 
            entre otras. En materia administrativa, contamos con un equipo de abogados experto en estatuto administrativo, 
            central y municipal. Defensa en sumarios, reclamos en Contraloría General, encasillamientos, 
            reconocimiento relación laboral a prestadores honorarios, traspaso forzado a contrata de los mismos, 
            recursos de protección, entre otras materias.
          </p>
          <a href="/tipos_de_denuncias" class="button w-button">Ver más</a>
        </div>
        <div class="div-block-14">
            <div style="padding-top:56.17021276595745%" class="video w-video w-embed"><iframe class="embedly-embed"
        src="https://cdn.embedly.com/widgets/media.html?src=https://www.youtube.com/embed/yzEYy-8AYvM?rel=0&enablejsapi=1&feature&oembed&display_name=YouTube&url=https://www.youtube.com/watch?v&Lc1CcXHPOX0&rel=0&enablejsapi=1&image=https://i.ytimg.com/vi/Lc1CcXHPOX0/hqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text/html&schema=youtube"
        scrolling="no" allowfullscreen="" title="Publicidad SosClickAbogados"></iframe></div>
        </div>
      </div>
    </div>
    <div class="section-6 wf-section">
      <div class="linea-2"></div>
      <h1 class="titulo-2">Abogados <br />conectados</h1>
    </div>
    <div class="section-5 wf-section">
      <div class="div-block-10">
        <div class="div-block-13">
          <img
            src="../../assets/images/Abogado-conectados.png"
            loading="lazy"
            srcset="
              ../../assets/images/Abogado-conectados.png 500w,
              ../../assets/images/Abogado-conectados.png 550w
            "
            sizes="(max-width: 479px) 280px, (max-width: 767px) 300px, (max-width: 991px) 380px, 100vw"
            alt=""
            class="image-6"
          />
        </div>
        <div class="div-block-11">
          <h1 class="heading-4 _2">Nuestros Abogados están ahí para tí</h1>
          <p class="paragraph-5 justificar">
            Nuestros especialistas laborales están atentos a tu llamado.<br><br>
            Recuerda que tus derechos laborales son irrenunciables, nosotros estamos aquí para defenderlos, cuenta con nosotros.<br><br>
            No esperes a que tu problema laboral llegue a ser uno personal, tus derechos importan, gestiones sin costo para ti, 
            solo pagas al ganar el juicio y un porcentaje marginal de lo que obtengas, por eso somos tus aliados en esto y estamos aquí para ti.
          </p>
          <a href="https://www.click2call.cl/?ShareLink=5" class="button _3 w-button">Contactar un Abogado</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "../helpers/api/planes";
export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
  }),
  mounted() {
    // if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
    //   this.getOrderStatus(this.$route.query.token);
    // }
    // setTimeout(() => {
    //   this.togglePlay();
    // }, 1000);
  },
  methods: {
    // togglePlay() {
    //   this.playing = !this.playing;
    //   if (this.playing) {
    //     this.$refs.videoRef.play();
    //   } else {
    //     this.$refs.videoRef.pause();
    //   }
    // },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          const payUrl =
            planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sos-abogados.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

.v-application a {
  color: #000 !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>