<template>
  <section class="footer-subscribe wf-section">
    <div class="container">
      <div class="footer-form-two w-form">
        <form id="wf-form-Footer-Form-Two" name="wf-form-Footer-Form-Two" data-name="Footer Form Two" method="get" class="footer-form-container-two">
          <div class="footer-form-title">Nuestro equipo te espera</div>
        </form>
      </div>
      <div class="hero-section wf-section"
           style="height: fit-content; background-image: linear-gradient(133deg, #0f446d 26%, #32717d)"
      >
        <div class="div-block-3"
             style="height: fit-content;  justify-content: center"
        >
          <div
              id="w-node-_68388a41-1492-a0f9-e803-23fbb9653b53-a17b101c"
              class="div-block-2"
              style="height: fit-content;  width: 70%; margin: 20px 0; min-width: 270px; max-width: unset;"

          >
      <Formulario />
          </div>
        </div>
      </div>
      <div class="footer-divider-two"></div>
      <div class="footer-bottom">
        <div class="footer-copyright">© 2022 Naturalphone . Todos los derechos reservados.</div>
        <!--div class="footer-legal-block">
          <a href="#" class="footer-legal-link">Terms Of Use</a>
          <a href="#" class="footer-legal-link">Privacy Policy</a>
        </div-->
      </div>
    </div>
  </section>
</template>

<script>
import Contacto from '@/components/Contacto/Contacto.vue'
import Formulario from "@/components/Contacto/Formulario";
export default {
  name: "Footer",
  components: {
    Formulario,
    Contacto
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style>
</style>