<template>
  <div
    data-animation="default"
    data-collapse="medium"
    data-duration="400"
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    class="navbar w-nav"
  >
    <div class="w-container">
      <a href="/" class="brand w-nav-brand">
        <h1 class="heading-5">SOSCLICKAbogados</h1>
      </a>
      <nav role="navigation" class="w-nav-menu">
        <a href="/como_funciona" class="w-nav-link">Como Funciona</a>
        <a href="/tipos_de_denuncias" class="w-nav-link">Tipos de denuncias</a>
        <a href="/contacto" class="w-nav-link">Contacto</a>
        <a
          href="/contacto"
          class="boton-ingresar w-nav-link"
          style="color: #fff !important"
          >Denunciar</a
        >
      </nav>
      <div class="w-nav-button">
        <div class="w-icon-nav-menu"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  // data: () => ({
  //   isMobile: false,
  // }),
  // beforeDestroy() {
  //   if (typeof window !== "undefined") {
  //     window.removeEventListener("resize", this.onResize, { passive: true });
  //   }
  // },
  // mounted() {
  //   this.onResize();
  //   window.addEventListener("resize", this.onResize, { passive: true });
  // },
  // methods: {
  //   onResize() {
  //     this.isMobile = window.innerWidth < 992;
  //   },
  // },
};
</script>
<style>
</style>