<template>
  <div>
    <h1 class="heading">Contacto</h1>
    <div class="div-block-4" style="margin-bottom: 20px"></div>
    <v-col cols="12" style="color: #707070; background-color: white; border-radius: 20px; padding: 20px">

      <v-form ref="form" :class="$vuetify.breakpoint.mobile ? 'px-0':'px-5'">
        <v-text-field
            v-model="userForm.name"
            :rules="[rules.required]"
            label="Nombre"
            placeholder="Nombre"
            validate-on-blur
            dense
            class="my-3"
        ></v-text-field>
        <v-text-field
            v-model="userForm.email"
            :rules="[rules.required, rules.email]"
            label="Email"
            placeholder="Email"
            validate-on-blur
            dense
            class="my-3"
        ></v-text-field>
        <v-text-field
            v-model="userForm.telefono"
            :rules="[rules.required, rules.phone]"
            label="Teléfono"
            placeholder="Teléfono"
            validate-on-blur
            dense
            class="my-2"
        ></v-text-field>

        <v-select
            v-model="userForm.tipoDenuncia"
            :items="items"
            :rules="[rules.required]"
            label=""
            dense
            class="my-2"

        ></v-select>
        <v-text-field
            v-model="userForm.direccion"
            :rules="[rules.required]"
            label="Dirección"
            placeholder="Dirección"
            validate-on-blur
            dense
            class="my-2"
        ></v-text-field>
        <v-textarea
            v-model="userForm.descripcion"
            label="Descripción"
            placeholder="Descripción"
            validate-on-blur
            class="my-2"
        ></v-textarea>


        <v-btn
            color="primary"
            class="mr-4"
            @click="submit"
        >Enviar
        </v-btn>

      </v-form>
    </v-col>

  </div>

</template>

<script>
export default {
  name: "Formulario",
  data() {
    return {
      rules: {
        required: value => !!value || 'Requerido',
        phone: value => value && value.length === 11 || 'Debe tener 11 dígtos. Ej (56912345678)',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Email no es válido'
        },
      },

      items: [
        "Despido injustificado.",
        "Despido indirecto",
        "Sindicatos (Constitución y asesorías)",
        "Negociación colectiva",
        "Accidente laboral y enfermedades profesionales",
        "Acoso laboral",
        "Autodespido",
        "Prenatal y post natal",
        "Muerte del trabajador",
        "Denuncias Inspección del Trabajo",
      ],
      userForm: {
        name: '',
        rut: '',
        email: '',
        telefono: '',
        direccion: '',
        tipoDenuncia: "Despido injustificado.",
        descripcion: '',
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        alert('Formulario enviado')
      }
    }
  }
}
</script>

<style scoped>

</style>