
<template>
  <div>
    <div class="hero-section wf-section"
         style="height: fit-content; min-height: 80vh !important; background-image: linear-gradient(133deg, #0f446d 26%, #32717d)"
    >
      <div class="div-block-3"
           style="height: fit-content; min-height: 80vh !important;"
      >
        <div
            id="w-node-_68388a41-1492-a0f9-e803-23fbb9653b53-a17b101c"
            class="div-block-2"
            style="height: fit-content; margin-top: 20px; margin-bottom: 20px; min-height: 80vh !important;max-width: 70%;"

        >
          <h1 class="heading">Tipos de Denuncia</h1>
          <div class="div-block-4" style="margin-bottom: 20px"></div>
          <h4 style="color: #fff;"> Despido injustificado.</h4>
          <p class="paragraph justificar">
            • Si consideras que no estás de acuerdo con la causal de tu despido porque consideras es cuestionable o no es verdadera, 
            puedes demandar por esta razón. Consideras que tu despido no tiene causal establecida en el Código del Trabajo, puedes 
            demandar por Despido Injustificado.<br><br>
            El despido injustificado es aquel en que el trabajador considera que la causal que aplica el empleador para desvincularlo, 
            se encuentra fundamentada en hechos que no son efectivos o en necesidades de la empresa que son cuestionables o inexistentes, 
            por ejemplo, cuando se contrata a una nueva persona de inmediato para la misma labor u otra similar. El plazo para reclamar 
            el despido injustificado, es de 60 días hábiles (no se cuentan domingos ni festivos). Este período se suspende si es que hay 
            reclamo en la Inspección del Trabajo, nunca superando los 90 días corridos desde el despido.

          </p>
          <h4 style="color: #fff;"> Autodespido.</h4>
          <p class="paragraph justificar">
            • Es la vía que permite al trabajador poner fin a su contrato por incumplimientos graves cometidos por el empleador. Estos 
            incumplimientos van desde el no pago de remuneraciones o cotizaciones previsionales hasta el acoso sexual, malos tratos o acoso. 
            En efecto, en caso de incumplimiento grave de las obligaciones establecidas en el contrato de trabajo, o en el no pago de 
            remuneraciones o cotizaciones previsionales, el trabajador puede utilizar la figura del “autodespido”. <br><br>
            El trabajador para autodespedirse, deberá comunicarlo por escrito al empleador, con copia a la Inspección del Trabajo respectiva, 
            indicando las causales y los hechos en que se basa dentro de los 3 días siguientes a su salida. El trabajador tiene un plazo de 60 días 
            hábiles desde la terminación de los servicios para demandar ante los tribunales de justicia (con la salvedad del reclamo a la inspección 
            señalado más arriba).

          </p>
          <h4 style="color: #fff;"> Tutela de Derechos.</h4>
          <p class="paragraph justificar">
            • El procedimiento de tutela laboral es una modalidad procesal que tiene por objeto conocer de lesiones a ciertos derechos fundamentales 
            de los trabajadores y, en caso que la lesión se confirme, obtener el cese de la conducta antijurídica y medidas reparatorias, además de una 
            sanción a beneficio del trabajador que va entre 6 y 11 remuneraciones, igualmente existe la posibilidad de agregar también el daño moral.
          </p>
          <h4 style="color: #fff;"> Accidentes del trabajo y enfermedades.</h4>
          <p class="paragraph justificar">
            • Si producto del trabajo sufres un accidente o desarrollas una enfermedad que te incapacite parcial o totalmente debes realizar la 
            denuncia para hacer valer tus derechos. Califica toda lesión que un trabajador sufre con ocasión de su trabajo y que le produzca 
            incapacidad total, parcial o la muerte. También se consideran aquellos accidentes que ocurren en trayecto desde su domicilio al trabajo 
            y viceversa.<br><br>
            El plazo para demandar en este caso es de 5 años.<br><br>
            Enfermedad Profesional.<br><br>
            • La Ley N° 16.744 establece que enfermedad profesional es la causada de una manera directa por el ejercicio de la profesión o del 
            trabajo que realiza una persona y que le produzca incapacidad o muerte.

          </p>
          <h4 style="color: #fff;"> Acoso Laboral.</h4>
          <p class="paragraph justificar">
            • El acoso laboral es toda conducta que constituya agresión u hostigamiento reiterados, ejercida por el empleador o por uno o más 
            trabajadores, en contra de otro u otros trabajadores, por cualquier medio, y que tenga como resultado para el o los afectados su 
            menoscabo, maltrato o humillación, o bien que amenace o perjudique su situación laboral o sus oportunidades en el empleo. 
            La persona víctima de acoso laboral debe hacer llegar su reclamo por escrito a la dirección de la empresa, establecimiento o 
            servicio en que trabaja y si la empresa no adopta ninguna medida para dar solución a los hechos, correspondería denunciar ante la 
            respectiva Inspección del Trabajo.
          </p>
          <h4 style="color: #fff;"> Acoso Sexual.</h4>
          <p class="paragraph justificar">
            • El acoso sexual se produce cuando una persona, independiente su género, realiza requerimientos de carácter sexual no consentidos por 
            la persona afectada y que perjudiquen su situación laboral o futuras oportunidades laborales. Si usted siente que ha sido víctima de 
            acoso sexual, notifíquelo de inmediato a su empleador. Si continúa el acoso, o si, de hecho, el acosador es su jefe, contáctenos hoy 
            mismo para discutir sus derechos legales y las opciones disponibles para usted para un litigio.
          </p>
          <h4 style="color: #fff;"> Fuero Maternal.</h4>
          <p class="paragraph justificar">
            • El fuero maternal se refiere al beneficio que la mujer trabajadora goza desde el inicio del embarazo y hasta un año después de la 
            fecha del parto, cualquiera sea su condición laboral, prestador a honorarios, funcionario público o trabajador por código del trabajo. 
          </p>
        
<!--          <br><a href="/contacto" class="button _2 w-button">Contáctanos</a>  <br><br>-->
          <h1 class="heading">Asesorías Permanentes</h1>
          <div class="div-block-4" style="margin-bottom: 20px"></div>
          <h4 style="color: #fff;"> A Empresas.</h4>
          <p class="paragraph justificar">
            • En ese sentido, dicho servicio implica actuar en representación de la empresa ante una eventual presentación (propuesta de convenio o 
            contrato colectivo) del sindicato, el cual inicie algún tipo de negociación no reglada o reglada.<br><br>
            Asimismo, contamos con planes de asesoría fija según las necesidades de su empresa, poniendo a disposición abogados 
            laboralistas expertos a tiempo completo a disposición de sus requerimientos y/o defensas. Por razonables montos mensuales 
            puedes ahorra mucho en defensas judiciales independientes, Llámanos.

          </p>
          <h4 style="color: #fff;"> A Sindicatos.</h4>
          <p class="paragraph justificar">
            • Considerando que día a día van surgiendo una serie de dudas en materia laboral, nuestra misión es efectuar un acompañamiento, el cual implique 
            la asistencia o asesoría de carácter extrajudicial o judicial, desde la revisión o visto bueno de contratos de trabajo, como consultas sencillas 
            a propósito de eventuales conflictos con los trabajadores de la empresa.
          </p>
<!--          <br><a href="/contacto" class="button _2 w-button">Contáctanos</a>  <br><br><br>-->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "../helpers/api/planes";
import Footer from "@/components/commons/Footer";
export default {
  name: "TiposDenuncia",
  components: {Footer},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
  }),
  mounted() {
    // if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
    //   this.getOrderStatus(this.$route.query.token);
    // }
    // setTimeout(() => {
    //   this.togglePlay();
    // }, 1000);
  },
  methods: {
    // togglePlay() {
    //   this.playing = !this.playing;
    //   if (this.playing) {
    //     this.$refs.videoRef.play();
    //   } else {
    //     this.$refs.videoRef.pause();
    //   }
    // },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
            planPayment.flowData &&
            planPayment.flowData.url &&
            planPayment.flowData.token
        ) {
          const payUrl =
              planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.media
              : "",
          fecha: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.date
              : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sos-abogados.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

.v-application a {
  color: #000 !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>